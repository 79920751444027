* {
  margin: 0px;
  padding: 0px;
  font-size: 16.5px;
  color: #4b4f58;
  max-width: 100%;
  word-spacing: 0.5px;
  letter-spacing: 0.5px;

}
header{
  background-size: cover;
  background-position: center;
  height: 80vh;
  background-attachment: fixed;
}
.home-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../Images/home-header.avif);
}
.contact-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../Images/contact-header.avif);
}
.about-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../Images/about-header.avif);
}
.services-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../Images/services-header.avif);
}
header h2,
header h1,
header p {
  color: white;
}
.bold{
  font-weight: bold;
  letter-spacing: .5px;
}
.nav-link {
  color: rgb(206, 206, 206);
  font-size: 100%;
}

header .nav-link:hover{
  color: white;
  transition: 0.5s;
}
.active {
  color: white;
}
.row {
  --bs-gutter-x: rem;
}
.list {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
}
.items {
  margin-right: 35px;
  color: #f1f1f1;
  cursor: pointer;
}
.nav-btn {
  display: none;
  width: 10%;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: #000;
  background-color: transparent;
  border: none;
  font-size: 18px;
}
header i{
  color: white;
  font-size: 30px;
}
@media screen and (max-width: 576px){
  .services-header{
    width: auto;
    height: 60%;
  }
  .about-header{
    width: auto;
    height: 60%;
  }
  .contact-header{
    width: auto;
    height: 60%;
  }
  .home-header{
    width: auto;
    height: 60%;
  }
  .list {
      flex-direction: column;
      width: 100%;
  }
  .items:nth-child(1){
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      margin-top: 50px;
  }
  .items {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.555);
      text-align: left;
      margin-right: 0px;
      padding: 10px 0;
  }
  .nav-btn {
      display: block;
  }
}
@media screen and (width: 400px){
.sections{
  display: none!important;
}
}
@media screen and (width: 912px){
.services-header{
  width: auto;
  height: 80%;
}
.about-header{
  width: auto;
  height: 80%;
}
.contact-header{
  width: auto;
  height: 80%;
}
.home-header{
  width: auto;
  height: 80%;
}
}

/* ///////////////////  Home - Section 1  ///////////////// */
.justify,
li,
ul {
  text-align: left;
}
.m-t-b{
  margin-top: 50px;
  margin-bottom: 50px;
}
.section-2,
.section-5,
.section-7,
.about-s1,
.about-s3,
.services-s1,
.services-s3 {
  background-color: rgb(243, 242, 242);
}
.section-2 li {
  margin-left: 16px;
}
.section-2-0:hover {
  border-style: solid;
  border-width: 2px;
  padding: 10px;
  border-radius: 5px;
}
.section-2-1:hover {
  border-color: #31b5e9;
}
.section-2-2:hover {
  border-color: #cf2e2e;
}
.section-2-3:hover {
  border-color: #ff6900;
}
.section-2-4:hover {
  border-color: #1d5a92;
}
.section-2-5:hover {
  border-color: #242a56;
}

/* ///////////////////  Home - Section 4  ///////////////// */
.section-4-1 {
  box-shadow: 0 4px 8px 0 rgba(109, 109, 109, 0.1),
    0 6px 20px 0 rgba(109, 109, 109, 0.1);
  text-align: center;
}
.section-4 h2 {
  color: #31b5e9;
}
/* ///////////////////  Home - Section 5 ///////////////// */
.section-5 img{
  width: 85%;
}
.section-5 img:hover{
  width: 100%;
  transition: width 0.8s;
}
/* ///////////////////  Home - Section 6  ///////////////// */
.section-6 input,
textarea {
  height: 50px;
  background-color: #fafafa;
  border-color: #eaeaea;
  width: 100%;
  padding: 12px;

}
.section-6 button {
  background-color: #0274be;
  border: none;
}
textarea {
  height: 200px;
}
/* ///////////////////  About - Section 1 ///////////////// */
.about-s2-1 {
  padding: 8%;
}
.about-s2-1 li {
  font-size: 18px;
}
/* ///////////////////  Services - Section 1 ///////////////// */
.services-s1-1,
.services-s2-1,
.services-s2-2,
.services-s3-1 {
  background-color: white;
  border-radius: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #cecece;
}
.services-s1-1:hover,
.services-s3-1:hover{
  box-shadow: 2px 2px 15px 10px #b3b2b263;
  border-width: 0px;
}
.services-s2-1:hover,
.services-s2-2:hover {
  background-color: #85c3db;
  color: white;
  box-shadow: 2px 2px 15px 10px #c6e5f1;
  border-width: 0px;
}
/* ///////////////////   Footer ///////////////// */
footer {
  background-image: linear-gradient(
      rgba(83, 83, 83, 0.85),
      rgba(83, 83, 83, 0.85)
    ),
    url(../Images/footer.jpg);
  background-size: cover;
  background-position: center;
}
footer p,
footer h2,
footer h5 {
  margin-top: 18px;
  color: white;
  font-weight: 400;
}
